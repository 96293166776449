jQuery(document).ready(function ($) {
    $(".button_text").click(function () {
        sessionStorage.setItem('sessionBanner', "yes");
        $(".banner").val("yes");
    })
    let thisBanner = sessionStorage.getItem('sessionBanner');
    let thisBannerAway = sessionStorage.getItem('awayBanner');
    if($(".banner").length>0){
        console.log("banner")
        $('#wrapper').addClass('extra');
    }else{
        $('#wrapper').removeClass('extra');
    }

    if (thisBanner != 'undefined') {
        $(".banner").val(thisBanner);
    }
    let headerHeight = $('#header').outerHeight();

    if (thisBannerAway == 'yes') {
        $('#offer-bar').addClass('away-now');
        headerHeight = $('#header').outerHeight();
        $('.header-spacing').css('padding-top', `${headerHeight}px`)
    }else{
        headerHeight = $('#header').outerHeight();
        $('.header-spacing').css('padding-top', `${headerHeight}px`)
    }
    
    $(".close").click(function () {
        let bannerheight = $('#offer-bar').outerHeight();
        $('#offer-bar').addClass('away');
        sessionStorage.setItem('awayBanner', "yes");
        $('.header-spacing').css('padding-top', `${(headerHeight-bannerheight)}px`)
    })
})