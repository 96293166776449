jQuery(document).ready(function ($) {
    
    // $("#home-vid").bind("ended", function () {
    //     console.log('ended 2');
    //     var hash = document.getElementById("products");

    //     if ($("#home-vid").is(":in-viewport")) {
    //         $('html, body').animate({
    //             scrollTop: $(hash).offset().top
    //         }, 800, function () {
    
    //             // Add hash (#) to URL when done scrolling (default click behavior)
    //             window.location.hash = hash;
    //         });
    //     }
    //     $(window).on('scroll', function () {
    //         $('video').each(function () {
    //             if ($(this).is(":in-viewport(-500)")) {
    //                 $(this)[0].play();
    //             }
    //             if (!$(this).is(":in-viewport")) {
    //                 $(this)[0].pause();
    //                 $(this)[0].currentTime = 0;
    //             }
    //         })
    //     })
    // });
});

$(function(){
    $('.review_carousel').slick({
        slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 12000,
		dots: false,
		arrows: true,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });


