jQuery(document).ready(function ($) {
    //get product data and set up objects
    // $(".product-data").each(function(i, element){
    //     thisName=  $(this).data('title');
    //     thisMini=  $(this).data('mini');
    //     thiscredit=  $(this).data('credit');
    //     thipocket=  $(this).data('pocket');
    //     thishandy=  $(this).data('handy');
    //     thisinfinity=  $(this).data('infinity');
    //     window[thisName]= {
    //         thisMini=  $(this).data('mini'),
    //         thiscredit=  $(this).data('credit'),
    //         thipocket=  $(this).data('pocket'),
    //         thishandy=  $(this).data('handy'),
    //         thisinfinity=  $(this).data('infinity'),
    //     }
    // })


    /// this is a bit of a mess.

// check if has come from a product page and pre enter details
    let thisProduct = sessionStorage.getItem('sessionProduct')
    $(".quote").click(function () {
        thisProduct = $(".quote").data("name");
        sessionStorage.setItem('sessionProduct', thisProduct);
    })


    let products = $(".product-select").html();
    $("span[data-name='sample'] .wpcf7-select").html(products);

    //setup dropdown variables for differnt products
    let cover = '<option class="placeholder" value="" disabled="" selected="">Select Cover Size...</option><option value="Mini">Mini</option><option value="Credit">Credit</option><option value="Pocket">Pocket</option><option value="Handy">Handy</option>'
    $("span[data-name='coverSize'] .wpcf7-select").html(cover);
    let format = '';
    let zformat = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x3">3x3</option><option value="4x3">4x3</option><option value="5x3">5x3</option><option value="6x3">6x3</option><option value="7x3">7x3</option><option value="8x3">8x3</option><option value="9x3">9x3 </option><option value="10x3">10x3 </option><option value="11x3">11x3 </option><option value="12x3">12x3 </option><option value="13x3">13x3"</option>'

    let kformat = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x2">3x2</option><option value="4x2">4x2</option><option value="5x2">5x2</option><option value="6x2">6x2</option><option value="7x2">7x2</option><option value="8x2">8x2</option><option value="9x2">9x2</option><option value="10x2">10x2</option><option value="11x2">11x2</option><option value="12x2">12x2</option><option value="13x2">13x2</option>'

    let cformat = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x1">3x1</option><option value="4x1">4x1</option><option value="5x1">5x1</option><option value="6x1">6x1</option><option value="7x1">7x1</option><option value="8x1">8x1</option><option value="9x1">9x1</option><option value="10x1">10x1</option><option value="11x1">11x1</option><option value="12x1">12x1</option><option value="13x1">13x1</option><option value="14x1">14x1</option>'

    let infinitySize = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="210 x 210mm">210 x 210mm (8" x 8")</option><option value="150 x 150mm">150 x 150mm (6" x 6")</option><option value="125 x 125mm">125 x 125mm (5" x 5")</option><option value="100 x 100mm">100 x 100mm (4" x 4")</option><option value="76 x 76mm">76 x 76mm (3" x 3")</option><option value="A5">A5 (8" x 6")</option><option value="A6">A6 (6" x 4")</option><option value="DL">DL (8" x 6")</option><option value="circle 156mm">Circle 156mm (6") diameter</option>'

    let zformatHandy = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x3">3x3 399 x 294mm</option><option value="4x3">4x3 399 x 392mm </option><option value="5x3">5x3 399 x 490mm</option><option value="6x3">6x3 399 x 588mm </option><option value="7x3">7x3 399 x 686mm</option><option value="8x3">8x3 399 x 784mm</option><option value="9x3">9x3 399 x 882mm</option><option value="10x3">10x3 399 x 980mm</option>'

    let kformatHandy = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x2">3x2 266 x 294mm </option><option value="4x2">4x2 266 x 392mm</option><option value="5x2">5x2 266 x 490mm </option><option value="6x2">6x2 266 x 588mm</option><option value="7x2">7x2 266 x 686mm </option><option value="8x2">8x2 266 x 784mm</option><option value="9x2">9x2 266 x 882mm</option><option value="10x2">10x2 266 x 980mm</option>'

    let cformatHandy = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x1">3x1 133 x 294mm </option><option value="4x1">4x1 133 x 392mm </option><option value="5x1">5x1 133 x 490mm </option><option value="6x1">6x1 133 x 588mm</option><option value="7x1">7x1 133 x 686mm</option><option value="8x1">8x1 133 x 784mm </option><option value="9x1">9x1 133 x 882mm</option><option value="10x1">10x1 133 x 980mm</option>'

    let zformatMini = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x3">3x3 180 x 120mm </option><option value="4x3">4x3 180 x 160mm </option><option value="5x3">5x3 180 x 200mm</option><option value="6x3">6x3 180 x 240mm</option><option value="7x3">7x3 180 x 280mm</option><option value="8x3">8x3 180 x 320mm</option><option value="9x3">9x3 180 x 360mm</option><option value="10x3">10x3 180 x 400mm</option><option value="11x3">11x3 180 x 440mm</option><option value="12x3">12x3 180 x 480mm</option><option value="13x3">13x3 180 x 520mm</option>'

    let kformatMini = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x2">3x2 120 x 120mm</option><option value="4x2">4x2 120 x 160mm</option><option value="5x2">5x2 120 x 200mm</option><option value="6x2">6x2 120 x 240mm</option><option value="7x2">7x2 120 x 280mm</option><option value="8x2">8x2 120 x 320mm</option><option value="9x2">9x2 120 x 360mm</option><option value="10x2">10x2 120 x 400mm</option><option value="11x2">11x2 120 x 440mm</option><option value="12x2">12x2 120 x 480mm</option><option value="13x2">13x2 120 x 520mm</option>'

    let cformatMini = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x1">3x1 60 x 120mm</option><option value="4x1">4x1 60 x 160mm</option><option value="5x1">5x1 60 x 200mm</option><option value="6x1">6x1 60 x 240mm </option><option value="7x1">7x1 60 x 280mm</option><option value="8x1">8x1 60 x 320mm</option><option value="9x1">9x1 60 x 360mm</option><option value="10x1">10x1 60 x 400mm</option><option value="11x1">11x1 60 x 440mm</option><option value="12x1">12x1 60 x 480mm</option><option value="13x1">13x1 60 x 520mm</option>'

    let zformatCredit = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x3">3x3 234 x 150mm</option><option value="4x3">4x3 234 x 200mm</option><option value="5x3">5x3 234 x 250mm</option><option value="6x3">6x3 234 x 300mm</option><option value="7x3">7x3 234 x 350mm </option><option value="8x3">8x3 234 x 400mm </option><option value="9x3">9x3 234 x 450mm </option><option value="10x3">10x3 234 x 500mm </option><option value="11x3">11x3 234 x 550mm</option><option value="12x3">12x3 234 x 600mm</option><option value="13x3">13x3 234 x 650mm</option>'

    let kformatCredit = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x2">3x2 156 x 150mm</option><option value="4x2">4x2 156 x 200mm</option><option value="5x2">5x2 156 x 250mm</option><option value="6x2">6x2 156 x 300mm</option><option value="7x2">7x2 156 x 350mm</option><option value="8x2">8x2 156 x 400mm</option><option value="9x2">9x2 156 x 450mm</option><option value="10x2">10x2 156 x 500mm</option><option value="11x2">11x2 156 x 550mm</option><option value="12x2">12x2 156 x 600mm</option><option value="13x2">13x2 156 x 650mm</option>'

    let cformatCredit = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x1">3x1  78 x 150mm</option><option value="4x1">4x1  78 x 200mm</option><option value="5x1">5x1  78 x 250mm</option><option value="6x1">6x1  78 x 300mm</option><option value="7x1">7x1  78 x 350mm</option><option value="8x1">8x1  78 x 400mm</option><option value="9x1">9x1  78 x 450mm</option><option value="10x1">10x1  78 x 500mm</option><option value="11x1">11x1  78 x 550mm</option><option value="12x1">12x1  78 x 600mm</option><option value="13x1">13x1  78 x 650mm</option>'

    let zformatPocket = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x3">3x3 297 x 210mm</option><option value="4x3">4x3 297 x 280mm</option><option value="5x3">5x3 297 x 350mm</option><option value="6x3">6x3 297 x 420mm</option><option value="7x3">7x3 297 x 490mm</option><option value="8x3">8x3 297 x 560mm</option><option value="9x3">9x3 297 x 630mm</option><option value="10x3">10x3 297 x 700mm</option><option value="11x3">11x3 297 x 770mm</option><option value="12x3">12x3 297 x 840mm</option><option value="13x3">13x3 297 x 910mm</option>'

    let kformatPocket = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x2">3x2 198 x 210mm</option><option value="4x2">4x2 198 x 280mm</option><option value="5x2">5x2 198 x 350mm</option><option value="6x2">6x2 198 x 420mm</option><option value="7x2">7x2 198 x 490mm</option><option value="8x2">8x2 198 x 560mm</option><option value="9x2">9x2 198 x 630mm</option><option value="10x2">10x2 198 x 700mm</option><option value="11x2">11x2 198 x 770mm</option><option value="12x2">12x2 198 x 840mm</option><option value="13x2">13x2 198 x 910mm</option>'

    let cformatPocket = '<option class="placeholder" value="" disabled="" selected="">Select layout...</option><option value="3x1">3x1  99 x 210mm</option><option value="4x1">4x1  99 x 280mm</option><option value="5x1">5x1  99 x 350mm</option><option value="6x1">6x1  99 x 420mm</option><option value="7x1">7x1  99 x 490mm</option><option value="8x1">8x1  99 x 560mm</option><option value="9x1">9x1  99 x 630mm</option><option value="10x1">10x1  99 x 700mm</option><option value="11x1">11x1  99 x 770mm</option><option value="12x1">12x1  99 x 840mm</option><option value="13x1">13x1  99 x 910mm</option>'

    $("span[data-name='insertFormat'] .wpcf7-select").html(zformat);

    $("span[data-name='coverSize']").addClass("greyOut");
    $("span[data-name='insertFormat']").addClass("greyOut");
    $("span[data-name='othersample']").addClass("greyOut");

    let sampleVal
    let otherSampleVal
    let coverVal

    function updateSelect() {
        $("span[data-name='insertFormat'] .wpcf7-select").empty();
        $("span[data-name='coverSize'] .wpcf7-select").empty();
        $("span[data-name='coverSize']").addClass("greyOut");
        $("span[data-name='insertFormat']").addClass("greyOut");
        $("span[data-name='othersample']").addClass("greyOut");

        format = "";
        formatOther = "";
        sampleVal = $("span[data-name='sample'] .wpcf7-select").val();
        sampleText = $("[data-title='" + sampleVal + "']")
        thisMini = $(sampleText).data('mini');
        thiscredit = $(sampleText).data('credit');
        thispocket = $(sampleText).data('pocket');
        thishandy = $(sampleText).data('handy');
        thisinfinity = $(sampleText).data('infinity');
        thisz = $(sampleText).data('zcard');
        thisk = $(sampleText).data('kcard');
        thisc = $(sampleText).data('ccard');
        thisi = $(sampleText).data('icard');

        if (sampleVal == "Infinity Card") {
            let infincover = '<option class="placeholder" value="Infinity Card" selected="">Infinity Card</option>';
            $("span[data-name='coverSize'] .wpcf7-select").html(infincover);
            $("span[data-name='insertFormat']").removeClass("greyOut");
            $("span[data-name='insertFormat'] .wpcf7-select").html(infinitySize);

        } else if (thisz == true || thisk == true || thisc == true || thisi == true) {
            $("span[data-name='othersample']").removeClass("greyOut");
            if (thisz == true) {
                format += '<option value="Z-Fold">Z-Fold</option>'
            }
            if (thisk == true) {
                format += '<option value="C-Fold">C-Fold</option>'
            }
            if (thisc == true) {
                format += '<option value="K-Fold">K-Fold</option>'
            }
            if (thisi == true) {
                format += '<option value="Handy">Handy</option>'
            }

            if (format == "") {
                $("span[data-name='othersample']").addClass("greyOut");
            } else {
                format = '<option class="placeholder" value="" disabled="" selected="">Select Cover Type...</option>' + format;
            }
            $("span[data-name='insertFormat'] .wpcf7-select").html(zformat);
            $("span[data-name='othersample'] .wpcf7-select").html(format);

            if (thisMini == true) {
                formatOther += '<option value="Mini">Mini</option>'
            }
            if (thiscredit == true) {
                formatOther += '<option value="Credit">Credit</option>'
            }
            if (thispocket == true) {
                formatOther += '<option value="Pocket">Pocket</option>'
            }
            if (thishandy == true) {
                formatOther += '<option value="Handy">Handy</option>'
            }

            if (thisinfinity == true) {
                formatOther += '<option value="infinity">infinity</option>'
            }

            formatOther = '<option class="placeholder" value="" disabled="" selected="">Select Cover Size...</option>' + formatOther;

            $("span[data-name='insertFormat'] .wpcf7-select").html(zformat);
            $("span[data-name='coverSize'] .wpcf7-select").html(formatOther);

        } else {
            $("span[data-name='coverSize']").removeClass("greyOut");
            if (thisMini == true) {
                format += '<option value="Mini">Mini 68mm x 48mm</option>'
            }
            if (thiscredit == true) {
                format += '<option value="Credit">Credit 85mm x 54mm</option>'
            }
            if (thispocket == true) {
                format += '<option value="Pocket">Pocket 108mm x 78mm</option>'
            }
            if (thishandy == true) {
                format += '<option value="Handy">Handy 146mm x 107mm</option>'
            }

            if (thisinfinity == true) {
                format += '<option value="infinity">infinity</option>'
            }
            if (format == "") {
                $("span[data-name='coverSize']").addClass("greyOut");
            } else {
                format = '<option class="placeholder" value="" disabled="" selected="">Select Cover Size...</option>' + format;
            }
            $("span[data-name='insertFormat'] .wpcf7-select").html(zformat);
            $("span[data-name='coverSize'] .wpcf7-select").html(format);

        }
    }

    if (thisProduct != 'undefined') {
        $("span[data-name='sample'] .wpcf7-select").val(thisProduct);
        updateSelect();
    }
    $("span[data-name='sample'] .wpcf7-select").change(function () {
        updateSelect();
    });
    $("span[data-name='othersample'] .wpcf7-select").change(function () {
        $("span[data-name='coverSize]").removeClass("greyOut");
        otherSampleVal = $("span[data-name='othersample'] .wpcf7-select").val();
    });
    $("span[data-name='coverSize'] .wpcf7-select").change(function () {
        console.log("change");
        $("span[data-name='insertFormat']").addClass("greyOut");
        coverVal = $("span[data-name='coverSize'] .wpcf7-select").val();
        console.log(coverVal);
        console.log(sampleVal);
        
        if (coverVal == "Handy") {
            if (sampleVal == "Z-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(zformatHandy);

            } else if (sampleVal == "C-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(cformatHandy);

            } else if (sampleVal == "K-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(kformatHandy);
            }
            }
          else if  (coverVal == "Mini") {
                if (sampleVal == "Z-Fold Card") {
                    $("span[data-name='insertFormat']").removeClass("greyOut");
                    $("span[data-name='insertFormat'] .wpcf7-select").html(zformatMini);
    
                } else if (sampleVal == "C-Fold Card") {
                    $("span[data-name='insertFormat']").removeClass("greyOut");
                    $("span[data-name='insertFormat'] .wpcf7-select").html(cformatMini);
    
                } else if (sampleVal == "K-Fold Card") {
                    $("span[data-name='insertFormat']").removeClass("greyOut");
                    $("span[data-name='insertFormat'] .wpcf7-select").html(kformatMini);
    
                }
            }
            else if  (coverVal == "Credit") {
                  if (sampleVal == "Z-Fold Card") {
                      $("span[data-name='insertFormat']").removeClass("greyOut");
                      $("span[data-name='insertFormat'] .wpcf7-select").html(zformatCredit);
      
                  } else if (sampleVal == "C-Fold Card") {
                      $("span[data-name='insertFormat']").removeClass("greyOut");
                      $("span[data-name='insertFormat'] .wpcf7-select").html(cformatCredit);
      
                  } else if (sampleVal == "K-Fold Card") {
                      $("span[data-name='insertFormat']").removeClass("greyOut");
                      $("span[data-name='insertFormat'] .wpcf7-select").html(kformatCredit);
      
                  }
                }
                else if  (coverVal == "Pocket") {
                      if (sampleVal == "Z-Fold Card") {
                          $("span[data-name='insertFormat']").removeClass("greyOut");
                          $("span[data-name='insertFormat'] .wpcf7-select").html(zformatPocket);
          
                      } else if (sampleVal == "C-Fold Card") {
                          $("span[data-name='insertFormat']").removeClass("greyOut");
                          $("span[data-name='insertFormat'] .wpcf7-select").html(cformatPocket);
          
                      } else if (sampleVal == "K-Fold Card") {
                          $("span[data-name='insertFormat']").removeClass("greyOut");
                          $("span[data-name='insertFormat'] .wpcf7-select").html(kformatPocket);
                      }
        } else {
            if (sampleVal == "Z-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(zformat);

            } else if (sampleVal == "C-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(cformat);

            } else if (sampleVal == "K-Fold Card") {
                $("span[data-name='insertFormat']").removeClass("greyOut");
                $("span[data-name='insertFormat'] .wpcf7-select").html(kformat);
            }
        }
    
    });
//change map

    $(".germany").click(function () {
        $(".title").removeClass("pink");
        $(".germany").addClass("pink");
        $("#map").html('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.9154789299005!2d11.462171615862097!3d48.092573162272956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dd9bd13be2d49%3A0x1bbf2824fadae251!2sAlpha%20Card%20Media%20Gmbh!5e0!3m2!1sen!2suk!4v1600871742450!5m2!1sen!2suk" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>')
    })
    $(".uk").click(function () {
        $(".title").removeClass("pink");
        $(".uk").addClass("pink");
        $("#map").html('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2353.463992561788!2d-1.6868480838834417!3d53.852396780088284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487be275dc6760b7%3A0x968e8d6acbc80e17!2sAlpha%20Card%20Compact%20Media%20Ltd!5e0!3m2!1sen!2suk!4v1602502996313!5m2!1sen!2suk" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>')
    })
    $(".france").click(function () {
        $(".title").removeClass("pink");
        $(".france").addClass("pink");
        $("#map").html('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.6829241549995!2d2.3078587158823796!3d48.883320806904734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fc7f84b6ed9%3A0x81227c77c2137e6d!2sMedia%20de%20Poche!5e0!3m2!1sen!2suk!4v1600872252433!5m2!1sen!2suk" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>')
    })
    $(".usa").click(function () {
        $(".title").removeClass("pink");
        $(".usa").addClass("pink");
        $("#map").html('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.058412996654!2d-81.36379008453356!3d28.598024392392002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7712e46a2ed73%3A0xa788ef1ab259812f!2sAlpha%20Card%20Compact%20Media%20LLC!5e0!3m2!1sen!2suk!4v1601044341147!5m2!1sen!2suk" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>')
    })

    $("[data-name='sampleOptin']").click(function () {
        if ($(this).find('input').prop('checked')) {
            $(this).addClass("active");
        } else {
            $(this).removeClass("active");
        }
    });
    $("[data-name='optin']").click(function () {
        if ($(this).find('input').prop('checked')) {
            $(this).addClass("active");
        } else {
            $(this).removeClass("active");
        }
    });

    $("[data-name='optin]'").addClass("active") ;
    $("[data-name='optin]'").attr('checked', true); 

    $("[data-name='sampleOptin]'").addClass("active") ;
    $("[data-name='sampleOptin]'").attr('checked', true); 
});