$(document).ready(function () {
    //set custom text fields to hidden
    $("#custom-text-field").css('display', "none");
    $("#custom-back-text-field").css('display', "none");
    $("#custom-website-text-field").css('display', "none");
    $("#custom-area-field-container").css('display', "none");
    $(".image-upload").css('display', "none");
    $(".teplate-row").css('display', "none");
    $(".cover-row").css('display', "none");
    $(".total").css('display', "none");

    let fontcolor
    let fontBottom
    let minDiscount = 0;
    let maxDiscount = 0;
    let minThreshold = 0;
    let maxThreshold = 0;
    let quantity = $(".quantity input").val();
    //get prices and thesholds from data elements 

    minDiscount = $("#data-eliment").data("mindiscount");
    maxDiscount = $("#data-eliment").data("maxdiscount");
    minThreshold = $("#data-eliment").data("minthreshold");
    maxThreshold = $("#data-eliment").data("maxthreshold");



    let startPrice = $(".amount bdi").html();
    let splicePrice = startPrice.replace('<span class="woocommerce-Price-currencySymbol">£</span>', '');
    let baceSplicePrice = splicePrice;

    if ($('.product-image-row').length) {
        $('.single_add_to_cart_button').addClass('hidden')
        $('.price').addClass('hidden')
        $('.quantity').addClass('hidden')

    }

    function updateStep() {

        let quantity = $(".quantity input").val();

        $(".amount bdi").html("<span class='total'>£" + (quantity * splicePrice).toFixed(2));
        if (quantity >= minThreshold && quantity <= maxThreshold && minThreshold && minDiscount) {
            $(".amount bdi").html("<span class='total'>£" + (quantity * minDiscount).toFixed(2));

        } else if (quantity >= maxThreshold && maxThreshold && maxDiscount) {
            $(".amount bdi").html("<span class='total'>£" + (quantity * maxDiscount).toFixed(2));
        } else {
            $(".amount bdi").html("<span class='total'>£" + (quantity * splicePrice).toFixed(2));
        }

        if (quantity >= stepthreshold && stepthreshold) {
            $(".quantity input")[0].setAttribute("step", steptwo);
            $(".quantity input")[0].setAttribute("min", 0);
        } else {
            $(".quantity input")[0].setAttribute("step", step);
            $(".quantity input")[0].setAttribute("min", minAmount);

        }
    }
    let step = 1;
    let steptwo = 1;
    let stepthreshold = 1;
    let minAmount = 1;
    if ($('#data-eliment').length > 0) {
        step = $("#data-eliment").data("step");
        steptwo = $("#data-eliment").data("steptwo");
        stepthreshold = $("#data-eliment").data("stepthreshold");
        minAmount = $("#data-eliment").data("minamount");
        minCustom = $("#data-eliment").data("mincustom");

    }
    $(".product-image-button").click(function () {
        let valueImg = $(this).data("productval");
        // $("#custom_select_field_variable").val(valueImg);
        document.getElementById('custom_select_field_variable').value = valueImg;
        updateDropdown()
    })

    function updateDropdown() {
        $(".hidden").addClass('show');
        let value = $("#custom_select_field_variable").val();

        $('#custom_select_field_variable_name option[data-name=' + value + "]").attr("selected", "selected");

        let imgFrontdata = $("#custom_select_field_variable").children("option:selected").data("imagefront");
        let imgBackdata = $("#custom_select_field_variable").children("option:selected").data("imageback");
        let stepVar = $("#custom_select_field_variable").children("option:selected").data("step");

        if (stepVar) {
            step = stepVar;
        } else {
            step = $("#data-eliment").data("step");

        }
        let steptwovar = $("#custom_select_field_variable").children("option:selected").data("steptwo");
        if (steptwovar) {
            steptwo = steptwovar;
        } else {
            steptwo = $("#data-eliment").data("steptwo");
        }
        let stepthresholdvar = $("#custom_select_field_variable").children("option:selected").data("stepthreshold");
        if (stepthresholdvar) {
            stepthreshold = stepthresholdvar;
        } else {
            stepthreshold = $("#data-eliment").data("stepthreshold");
        }
        let minAmountvar = $("#custom_select_field_variable").children("option:selected").data("minamount");
        if (minAmountvar) {
            minAmount = minAmountvar;
        } else {
            minAmount = $("#data-eliment").data("minamount");
        }
        let minCustomvar = $("#custom_select_field_variable").children("option:selected").data("mincustom");
        if (minCustomvar) {
            minCustom = minCustomvar;
        } else {
            minCustom = $("#data-eliment").data("mincustom");
        }
        let minDiscountvar = $("#custom_select_field_variable").children("option:selected").data("mindiscount");
        if (minDiscountvar) {
            minDiscount = minDiscountvar;
        } else {
            minDiscount = $("#data-eliment").data("mindiscount");
        }
        let maxDiscountvar = $("#custom_select_field_variable").children("option:selected").data("maxdiscount");
        if (maxDiscountvar) {
            maxDiscount = maxDiscountvar;
        } else {
            maxDiscount = $("#data-eliment").data("maxdiscount");
        }

        let minThresholdvar = $("#custom_select_field_variable").children("option:selected").data("minthreshold");
        if (minThresholdvar) {
            minThreshold = minThresholdvar;
        } else {
            minThreshold = $("#data-eliment").data("minthreshold");
        }

        let maxThresholdvar = $("#custom_select_field_variable").children("option:selected").data("maxthreshold");
        if (maxThresholdvar) {
            maxThreshold = maxThresholdvar;
        } else {
            maxThreshold = $("#data-eliment").data("maxthreshold");
        }

        let splicePricevar = $("#custom_select_field_variable").children("option:selected").data("baseprice");
        if (splicePricevar) {
            splicePrice = splicePricevar;
        } else {
            splicePrice = baceSplicePrice;
        }
        let cover = $("#custom_select_field_variable").children("option:selected").data("cover");
        $(".woocommerce-product-gallery__wrapper").html('<img src="' + cover + '" class="wp-post-image sticky" alt="" loading="lazy" title="alpha-revision-guide-maths-and-english-1" data-caption="" data-src="' + cover + '" data-large_image="' + cover + '" data-large_image_width="1280" data-large_image_height="853" srcset="' + cover + ' 1280w, https://alpha-cards.com/wp-content/uploads/2020/09/alpha-revision-guide-maths-and-english-1-300x200.jpg 300w, https://alpha-cards.com/wp-content/uploads/2020/09/alpha-revision-guide-maths-and-english-1-1024x682.jpg 1024w, https://alpha-cards.com/wp-content/uploads/2020/09/alpha-revision-guide-maths-and-english-1-768x512.jpg 768w" sizes="(max-width: 1280px) 100vw, 1280px" width="1280" height="853">');

        //get color data from element 
        fontcolor = $("#custom_select_field_variable").children("option:selected").data("fontcolor");
        //apply color data to font
        $(".textarea").css('color', fontcolor);


        //get color data from element 
        fontBottom = $("#custom_select_field_variable").children("option:selected").data("fontcolorbottom");
        //apply color data to font
        console.log(fontBottom)
        $(".text-box p").css('color', fontBottom);

        updateStep();

        $('.front').css('background-image', "url(" + imgFrontdata + ")");
        $('.back').css('background-image', "url(" + imgBackdata + ")");
    }
    $("#custom_select_field_variable").change(function () {
        updateDropdown()
    })

    $("#custom_select_field_title").change(function () {
        let sel = $("#custom_select_field_title").val();
        if (sel == "part-custom") {
            $("#custom-text-field").css('display', "flex");
            $("#custom-back-text-field").css('display', "flex");
            $(".image-upload").css('display', "flex");
            $(".teplate-row").css('display', "block");
            $("#custom-website-text-field").css('display', "flex");
            $("#custom-area-field-container").css('display', "flex");
            $(".woocommerce-product-gallery").css('display', "none");
            minAmount = minCustom;
            $(".quantity input")[0].setAttribute("min", minAmount);
            $(".quantity input").val(minAmount);
            updateStep();

            $(".cover-row").css('display', "none");
            $(".download").css('display', "none");
            $("#uploadTrigger").text("Upload logo");




        } else if (sel == 'full-custom') {
            minAmount = minCustom;
            $(".quantity input")[0].setAttribute("min", minAmount);
            $(".quantity input").val(minAmount);
            updateStep();

            $(".cover-row").css('display', "flex");
            $("#custom-text-field").css('display', "none");
            $("#custom-back-text-field").css('display', "none");
            $(".image-upload").css('display', "flex");
            $(".download").css('display', "flex");

            $(".teplate-row").css('display', "none");
            $("#custom-website-text-field").css('display', "none");

            $("#custom-area-field-container").css('display', "none");
            $(".woocommerce-product-gallery").css('display', "none");
            $("#uploadTrigger").text("Upload file");


        } else {
            $("#custom-text-field").css('display', "none");
            $("#custom-back-text-field").css('display', "none");
            $(".image-upload").css('display', "none");
            $(".teplate-row").css('display', "none");
            $("#custom-area-field-container").css('display', "none");
            $("#custom-website-text-field").css('display', "none");
            $(".woocommerce-product-gallery").css('display', "block");
            $(".cover-row").css('display', "none");


            minAmount = $("#data-eliment").data("minamount");

            $(".quantity input")[0].setAttribute("min", minAmount);
        }
    });

    $("#cfwc-area-field").keyup(function (event) {
        let inputText
        if (event.which == 13) {
            let text = $("#cfwc-area-field").val();
            var splitted = text.split("\n");
            if (splitted.length < 6) {
                inputText = splitted.join('<br>');
                $(".textarea").html(inputText);
            }
        }
        //get val
        let text = $("#cfwc-area-field").val();
        //split on line break
        var splitted = text.split("\n");

        //remake into string with linebreaks
        if (splitted.length < 6) {

            inputText = splitted.join('<br>');
            //add to textarea paragraph
            $(".textarea").html(inputText);
        }
    })
    $("#cfwc-area-field").change(function () {
        let inputText
        let text = $("#cfwc-area-field").val();
        var splitted = text.split("\n");
        inputText = splitted.join('<br>');
        $(".textarea").html(inputText);
        if ($(".swap").text() == "See Back") {
            swapCard();
        }
    });

    $("#cfwc-title-field").keypress(function (event) {
        if (event.which == 13) {
            event.preventDefault();
            let text = $("#cfwc-title-field").val();
            $(".custom-text").text(text);
        }
    })
    $("#cfwc-title-field").keyup(function (event) {

        let text = $("#cfwc-title-field").val();
        $(".custom-text").text(text);
        textLength = text.length;
        if (textLength >= 20) {
            var fontSize = 150 - (2.85 * textLength);
            $(".card-template .text-box .custom-text").css('font-size', fontSize + "%");
        } else {
            $(".card-template .text-box .custom-text").css('font-size', '100%');

        }
    })
    $("#cfwc-title-field").change(function () {
        let text = $("#cfwc-title-field").val();
        $(".custom-text").text(text);
        if ($(".swap").text() == "See Back") {
            swapCard();
        }
    });

    $("#cfwc-back-title-field").keypress(function (event) {
        if (event.which == 13) {
            event.preventDefault();
            let text = $("#cfwc-back-title-field").val();
            $(".custom-text-back").text(text)
        }
    })
    $("#cfwc-back-title-field").keyup(function (event) {
        let text = $("#cfwc-back-title-field").val();
        $(".custom-text-back").text(text);

    })
    $("#cfwc-back-title-field").change(function () {
        let text = $("#cfwc-back-title-field").val();
        $(".custom-text-back").text(text);
        textLength = text.length;
        if (textLength >= 20) {
            var fontSize = 150 - (2.85 * textLength);
            $(".card-template .text-box .custom-text-back").css('font-size', fontSize + "%");
        } else {
            $(".card-template .text-box .custom-text-back").css('font-size', '100%');

        }
        if ($(".swap").text() == "See Back") {
            swapCard();
        }
    });

    $("#cfwc-website-title-field").keypress(function (event) {
        if (event.which == 13) {
            event.preventDefault();
            let text = $("#cfwc-website-title-field").val();
            $(".custom-text-back-two").text(text)
        }
    })
    $("#cfwc-website-title-field").keyup(function (event) {

        let text = $("#cfwc-website-title-field").val();
        $(".custom-text-back-two").text(text);
    })
    $("#cfwc-website-title-field").change(function () {
        let text = $("#cfwc-website-title-field").val();
        $(".custom-text-back-two").text(text);
        textLength = text.length;
        if (textLength >= 20) {
            var fontSize = 150 - (2.85 * textLength);
            $(".card-template .text-box .custom-text-back-two").css('font-size', fontSize + "%");
        } else {
            $(".card-template .text-box .custom-text-back-two").css('font-size', '100%');

        }
        if ($(".swap").text() == "See Back") {
            swapCard();
        }
    });

    $(".swap").click(function () {
        swapCard();
    });

    function swapCard() {
        if ($(".swap").text() == "See Back") {
            $(".swap").text("See Front");
        } else {
            $(".swap").text("See Back");

        }

        $(".ontop").addClass("right");
        setTimeout(() => {
            $(".right").removeClass("right");
        }, 300);

        $(".card-template").toggleClass("ontop");
    }

    $("#uploadTrigger").click(function (event) {
        event.preventDefault();
        $("#image-upload").click();
        if ($(".swap").text() == "See Front") {
            swapCard();
        }
    });
    if ($('#data-eliment').length > 0) {
        minDiscount = $("#data-eliment").data("mindiscount");
        maxDiscount = $("#data-eliment").data("maxdiscount");
        minThreshold = $("#data-eliment").data("minthreshold");
        maxThreshold = $("#data-eliment").data("maxthreshold");
        $(".amount bdi").html("<span class='total'>£" + (quantity * splicePrice).toFixed(2));

        $(".quantity input").change(function () {
            let quantity = $(".quantity input").val();

            if (quantity >= minThreshold && quantity <= maxThreshold && minThreshold && minDiscount) {
                lowPriceQuantity = quantity - minThreshold;
                $(".amount bdi").html("<span class='total'>£" + ((lowPriceQuantity * minDiscount) + (minThreshold * splicePrice)).toFixed(2));

            } else if (quantity >= maxThreshold && maxThreshold && maxDiscount) {
                lowPriceQuantity = quantity - maxThreshold;
                midPrice = maxThreshold - minThreshold;
                $(".amount bdi").html("<span class='total'>£" + ((lowPriceQuantity * maxDiscount) + (midPrice * minDiscount) + (minThreshold * splicePrice)).toFixed(2));
            } else {
                $(".amount bdi").html("<span class='total'>£" + (quantity * splicePrice).toFixed(2));
            }
        })


        $(".quantity input").change(function () {
            let quantity = $(".quantity input").val();
            if (quantity >= stepthreshold && stepthreshold) {
                $(".quantity input")[0].setAttribute("step", steptwo);
                $(".quantity input")[0].setAttribute("min", (0));
            } else {
                $(".quantity input")[0].setAttribute("step", step);
                $(".quantity input")[0].setAttribute("min", minAmount);

            }
        })
    } else {
        $(".quantity input").change(function () {
            let quantity = $(".quantity input").val();

            if (quantity >= minThreshold && quantity <= maxThreshold && minThreshold && minDiscount) {
                $(".amount bdi").html("£" + minDiscount);

            } else if (quantity >= maxThreshold && maxThreshold && maxDiscount) {
                $(".amount bdi").html("£" + maxDiscount);
            } else {
                $(".amount bdi").html(startPrice);
            }
        })


        $(".quantity input").change(function () {
            let quantity = $(".quantity input").val();
            if (quantity >= stepthreshold && stepthreshold) {
                $(".quantity input")[0].setAttribute("step", steptwo);
                $(".quantity input")[0].setAttribute("min", (0));
            } else {
                $(".quantity input")[0].setAttribute("step", step);
                $(".quantity input")[0].setAttribute("min", minAmount);

            }
        })
    }

});