jQuery(document).ready(function ($) {
    if ($('#products_page').length > 0) { // if color-bar-top element exists in DOM
        $(window).on('scroll', function () {
            $('video').each(function () {
                if ($(this).is(":in-viewport(-500)")) {
                    $(this)[0].play();
                }
                if (!$(this).is(":in-viewport")) {
                    $(this)[0].pause();
                    $(this)[0].currentTime = 0;
                }
            })
        })
    }
});

jQuery(document).ready(function ($) {
    if ($('#single_products_page').length > 0) { // if color-bar-top element exists in DOM
        $(window).on('scroll', function () {
            $('video').each(function () {
                if ($(this).is(":in-viewport(-500)")) {
                    $(this)[0].play();
                }
                if (!$(this).is(":in-viewport")) {
                    $(this)[0].pause();
                    $(this)[0].currentTime = 0;
                }
            })
        })
    }
});