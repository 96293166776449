jQuery(document).ready(function ($) {
	$('#img-row').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1250,
		dots: false,
        arrows: false,
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]

    });
    $('#img-row').each(function () {
		// $(this).magnificPopup({
		// 	delegate: 'a', // child items selector, by clicking on it popup will open
		// 	type: 'image',
		// 	gallery: {
		// 		enabled: true,
		// 		// navigateByImgClick: true,
		// 		preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		// 	},
		// 	zoom: {
		// 		enabled: true,
		// 		easing: 'ease-in-out',
		// 		duration: 300 // don't foget to change the duration also in CSS
		// 	},
		// 	image: {
		// 		tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
		// 		titleSrc: function (item) {
		// 			return item.el.attr('title');
		// 		}
		// 	}
		// 	// other options
		// });
		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			gallery: {
			  enabled:true
			}
		});
  })
  
  $('#single-slider').slick({
		slidesToShow: 1,
    fade: true,
		autoplay: true,
		autoplaySpeed: 1250,
		dots: false,
        arrows: false,
        // responsive: [
        //     {
        //       breakpoint: 1600,
        //       settings: {
        //         slidesToShow: 4,
        //         slidesToScroll: 3,
        //       }
        //     },
        //     {
        //       breakpoint: 1024,
        //       settings: {
        //         slidesToShow: 3,
        //         slidesToScroll: 3,
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 2,
        //         slidesToScroll: 2
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          // ]

    });
});