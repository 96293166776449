$(document).on('submit', '#company-logo-form', function (e) {
    // $('#company-logo-form').on('submit', function(e) {
    e.preventDefault();
    $('#img-upload-error').text("");

    let fileData = $(this).find('input[type=file]').prop('files')[0];
    let maxSize = 1048576; // 1mb

    console.log(fileData)
    let formData = new FormData();

    formData.append('logo_img_file', fileData);
    formData.append('action', 'addpdf');


    $.ajax({
        url: ajax_url,
        type: 'POST',
        contentType: false,
        processData: false,
        dataType: 'json',
        data: formData,
        error: function (res) {
            console.log(res);
        },
        success: function (res) {
            console.log(JSON.stringify(res))
            console.log(res);
            if (res.error) {
                $('#img-upload-error').text(res.error);
            } else {

                console.log(fail);
                console.log(res);

                // replace upload form with delete section
                let html = '' +
                    '<p id="img-delete-error"></p>' +
                    res.image +
                    '<button type="button" class="btn bg green" id="delete-logo">Delete Logo</button>';

                $('#company-logo-section .body').html(html);
            }
        }
    });

});