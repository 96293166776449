jQuery(document).ready(function ($) {
    if ($('#hero1').length > 0) {
        if (window.innerWidth >= 576) {
            $('.video').html(`<video id="home-vid" width="100%" height="100%" controls="true" autoplay="autoplay" muted playsinline preload="none">
			<source src="https://alpha-cards.com/wp-content/themes/alphaTheme/images/video/ALPHACARDv5.webm" type="video/webm">
			<source src="https://alpha-cards.com/wp-content/themes/alphaTheme/images/video/ALPHACARD v5.mp4" type="video/mp4">
        </video>`);
        $("#home-vid").bind("ended", function () {
            console.log('ended 2');
            var hash = document.getElementById("products");
    
            if ($("#home-vid").is(":in-viewport")) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 800, function () {
        
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.location.hash = hash;
                });
            }
            $(window).on('scroll', function () {
                $('video').each(function () {
                    if ($(this).is(":in-viewport(-500)")) {
                        $(this)[0].play();
                    }
                    if (!$(this).is(":in-viewport")) {
                        $(this)[0].pause();
                        $(this)[0].currentTime = 0;
                    }
                })
            })
        });
        } else {
            $('.video').html(`<video id="home-vid" width="100%" height="100%" controls="true" autoplay="autoplay" muted playsinline preload="none">
			<source src="https://alpha-cards.com/wp-content/themes/alphaTheme/images/video/ALPHACARDv5(1).webm" type="video/webm">
			<source src="https://alpha-cards.com/wp-content/themes/alphaTheme/images/video/ALPHACARD v5(1).mp4" type="video/mp4">
        </video>`);
        $("#home-vid").bind("ended", function () {
            console.log('ended 2');
            var hash = document.getElementById("products");
    
            if ($("#home-vid").is(":in-viewport")) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 800, function () {
        
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.location.hash = hash;
                });
            }
            $(window).on('scroll', function () {
                $('video').each(function () {
                    if ($(this).is(":in-viewport(-500)")) {
                        $(this)[0].play();
                    }
                    if (!$(this).is(":in-viewport")) {
                        $(this)[0].pause();
                        $(this)[0].currentTime = 0;
                    }
                })
            })
        });
        }
    }

})