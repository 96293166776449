jQuery(document).ready(function ($) {
    $('#header_nav .menu-item').mouseenter(
        function () {
            //check to see if menu-item is part of a submenu
            if (!$(this).parent().hasClass('sub-menu')) {
                $('.sub-menu').removeClass('open');
                $('#header').removeClass('open');

                $(this).find('.sub-menu').addClass('open');

                $(this).addClass('open');
                if ($('.sub-menu').hasClass('open')) {
                    $('#header').addClass('open');
                }

            }
        }
    );
    $('#header_nav .menu-item').mouseleave(
        function () {
            //check to see if menu-item is part of a submenu
            if (!$(this).parent().hasClass('sub-menu')) {
                $('.sub-menu').removeClass('open');
                $(this).removeClass('open');
                $('#header').removeClass('open');

            }
        }
    );
    $(".mobile-nav-button").click(function () {
        $(".nav-closed").toggleClass("open");
        $(".bar1").toggleClass("change");
        $(".bar2").toggleClass("change");
        $(".bar3").toggleClass("change");
    });


})
$(window).on("load", function () {
    //header spacing
    let headerHeight = $('#header').outerHeight();
    $('.header-spacing').css('padding-top', `${headerHeight}px`)
});