jQuery(document).ready(function ($) {
  var slider = document.getElementById("myRange");
  var image = document.getElementById("img-change");
  // get data from eliment
  const rangeMin = $('.slider-bar').data('min');
  const rangeMax = $('.slider-bar').data('max');

  function onChange(event) {
    var x = event.target.value;

    if (x == 3) {
      image.className = "MyClass-0";
    } else if (x == 4) {
      image.className = "MyClass-1";
    } else if (x == 5) {
      image.className = "MyClass-2";
    } else if (x == 6) {
      image.className = "MyClass-3";
    } else if (x == 7) {
      image.className = "MyClass-4";
    } else if (x == 8) {
      image.className = "MyClass-5";
    } else if (x == 9) {
      image.className = "MyClass-6";
    } else if (x == 10) {
      image.className = "MyClass-7";
    } else if (x == 11) {
      image.className = "MyClass-8";
    } else if (x == 12) {
      image.className = "MyClass-9";
    } else if (x == 13) {
      image.className = "MyClass-10";
    } else if (x == 14) {
      image.className = "MyClass-11";
    };
    $('.number').text(x);
    let thiswidth = $('.slider-bar').outerWidth();
    thiswidth = thiswidth - 30;
    //get the length of the range
    const xLength = rangeMax-rangeMin;
    // work out how far each point on the range is seperated by
    left = thiswidth / xLength;
    // move the nuber eliment by the xlength +a bit of fine tuning
    $('.number').css('left', `${(x-rangeMin)*left+7}px`) 
  }
  slider.addEventListener("input", onChange);

  $("#cover-size").click(function () {
    $("#sizes").hide();
    $("#cover").show();
    $("#size0").addClass("active");
  });

  $("#insert-size").click(function () {
    $("#sizes").show();
    $("#cover").hide();
  });
});

jQuery(document).ready(function ($) {
  $("#size-btn0").click(function () {
    $(".cover-info").removeClass("active");
    $("#size0").addClass("active");
  });
  $("#size-btn1").click(function () {
    $(".cover-info").removeClass("active");
    $("#size1").addClass("active");
  });
  $("#size-btn2").click(function () {
    $(".cover-info").removeClass("active");
    $("#size2").addClass("active");
  });
  $("#size-btn3").click(function () {
    $(".cover-info").removeClass("active");
    $("#size3").addClass("active");
  });



  $('#infinity-slide').slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    responsive: [{
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {

          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]

  });
});